import * as _ from 'lodash'
import { isProduction, parseInstance } from '../../utils/utils'
import * as axios from 'axios'
import { CustomField } from '../../constants/field-types'
import { FORMS_APP_DEF_ID } from '../../constants'

const formsBaseUrl = () => (isProduction() ? 'editor.wix.com/wix-form-builder' : 'localhost:3001')
const hasQuery = (uri: string) => _.includes(uri, '?')
const getContactsUrl = () => `https://${formsBaseUrl()}/contacts/v1`

const FORMS_URL = 'https://editor.wix.com/_api/wix-form-builder-web'
const PLATFORMIZED_FORMS_URL = `${FORMS_URL}/v1`
const PLATFORMIZED_PAYMENTS_URL = 'https://editor.wix.com/_api/payment-services-web/payments/v2'
const CONTACTS_URL = 'https://editor.wix.com/_api/wix-contacts-webapp/v1'

const PREDEFINED_LABELS = ['contacts-contacted_me', 'contacts-customers']

export default class RemoteApi {
  private boundEditorSDK
  private experiments

  constructor({ boundEditorSDK, experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.experiments = experiments
  }

  public getLabels = async (): Promise<any> =>
    this._platformizedRequest({
      url: CONTACTS_URL,
      endpoint: 'metadata/labels',
      method: 'GET',
    }).then(data => {
      return _.map(
        _.filter(
          data.labels,
          label => label.type === 'USER' || _.includes(PREDEFINED_LABELS, label.id)
        ),
        label => ({
          id: label.id,
          name: label.details.name,
        })
      )
    })

  public getOwnerEmail = async () => this._get(getContactsUrl(), 'get-owner-email')

  public getEmailById = async emailId => this._get(getContactsUrl(), `get-email?emailId=${emailId}`)

  public getEmailsById = async (emailId, secondEmailId) =>
    this._get(getContactsUrl(), `get-emails?emailId=${emailId}&secondEmailId=${secondEmailId}`)

  public getTags = async () => this._get(getContactsUrl(), 'get-tags')

  public createTag = async tagName => this._post(getContactsUrl(), 'create-tag', { tagName })

  public updateTag = async (tagId, newName) =>
    this._post(getContactsUrl(), 'update-tag', { tagId, newName })

  public getCustomFields = async () => this._get(getContactsUrl(), 'get-custom-fields')

  public insertEmail = async email => this._post(getContactsUrl(), 'insert-email', { email })

  public createCustomField = async (field: CustomField) =>
    this._post(getContactsUrl(), 'create-custom-field', { field })

  public publishSite = data =>
    this._platformizedRequest({
      url: PLATFORMIZED_FORMS_URL,
      endpoint: 'publish-site',
      method: 'POST',
      data,
    })

  public editDraft = form =>
    this._platformizedRequest({
      url: PLATFORMIZED_FORMS_URL,
      endpoint: 'edit-draft',
      method: 'POST',
      data: { form: form },
    }).catch(() => null)

  public getPremiumRestrictions = () => this._get(FORMS_URL, 'premium/restrictions')

  public getRestrictedKeywords = () => this._get(getContactsUrl(), 'keywords')

  public getConnectedPayments = async () => {
    const appInstance = await this.boundEditorSDK.info.getAppInstance()
    const instanceId = parseInstance(appInstance).instanceId
    const endpoint = `accounts/${FORMS_APP_DEF_ID}:${instanceId}/payment-methods`

    return this._platformizedRequest({ url: PLATFORMIZED_PAYMENTS_URL, endpoint, method: 'GET' })
  }

  private _get = (url, endpoint) => this._request({ url, endpoint, method: 'GET' })
  private _post = (url, endpoint, data) => this._request({ url, endpoint, method: 'POST', data })

  private async _platformizedRequest({ url, endpoint, method, data = undefined }) {
    const isTemplate = !(await this.boundEditorSDK.info.isSiteSaved())

    if (isTemplate) {
      return Promise.resolve()
    }

    const appInstance = await this.boundEditorSDK.info.getAppInstance()

    const response = await axios.request({
      headers: { Authorization: appInstance },
      method,
      url: `${url}/${endpoint}`,
      data,
    })

    return _.get(response, 'data')
  }

  private async _request({ url, endpoint, method, data = undefined }) {
    const isTemplate = !(await this.boundEditorSDK.info.isSiteSaved())
    const appInstance = await this.boundEditorSDK.info.getAppInstance()
    const response = !isTemplate
      ? await axios.request({
          headers: { 'X-Wix-With-Static-Templates': 'true' },
          method,
          url: `${url}/${endpoint}${hasQuery(endpoint) ? '&' : '?'}instance=${appInstance}`,
          data,
        })
      : null
    return _.get(response, 'data')
  }
}
